import React, { useState } from "react";

const Carusel = () => {
    const [imageNumber, setImageNumber] = useState(1507); // Начальный номер изображения

    const handleNext = () => {
        if (imageNumber >= 1540) {
            setImageNumber(1474)
        } else {
            setImageNumber(prevNumber => prevNumber + 1);
        }

    };

    const handlePrev = () => {
        if (imageNumber <= 1474) {
            setImageNumber(1540)
        } else {
            setImageNumber(prevNumber => prevNumber - 1);
        }

    };

    return (
        <>
            <img src={`/img/${imageNumber}.png`} alt="NFT" data-src={`/img/${imageNumber}.png`} loading="lazy"/>
            <div className="pointers">
                <div className="prev" onClick={handlePrev}>Prev</div>
                <div className="next" onClick={handleNext}>Next</div>
            </div>
        </>
    );
};

export default Carusel;