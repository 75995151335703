import React from "react";
import '../src/style.css'
import { useState, useEffect } from 'react';
import { useAccount, usePrepareContractWrite, useContractWrite, useContractReads, useWaitForTransaction, useEnsName } from 'wagmi';
import contractInterface from "../src/config/abi.json";
import {
    useConnectModal,
    useAccountModal
} from '@rainbow-me/rainbowkit';
import { useDebounce } from 'use-debounce';
import Popup from '../src/components/popup'
import Carusel from '../src/components/carusel'
import $ from "jquery";
import axios from 'axios';


function App() {
    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const [ChainError, setChainError] = useState(false);
    const [MaxMintWL, setMaxMintWL] = useState(false);
    const [MaxMintPb, setMaxMintPb] = useState(false);
    const [UserRejected, setUserRejected] = useState(false);
    const [NotFUNDS, setNotFUNDS] = useState(false);
    const [OnSuccessPopup, setOnSuccessPopup] = useState(false);
    const [OnSuccessWLPopup, setOnSuccessWLPopup] = useState(false);
    const [DataLoading, setDataLoading] = useState(true);
    const [isWl, setIsWl] = useState(true);
    const [InputError, setInputError] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [wlWallet, setwlWallet] = useState(true);
    const [AmountForPay, setAmountForPay] = useState(0);
    const links = {
        Twitter: "https://twitter.com/pointfarmors",
        Etherscan: "https://etherscan.io/address/0x37ef980d65841c4c018e2a514a91ea07d7772ba7",
        OpenSea: "https://opensea.io/collections/pointfarmors"
    }
    const { isConnected, isDisconnected, isConnecting, address, connector: activeConnector } = useAccount();
    const ensName = useEnsName({
        address: address,
        onSuccess(data) {
            console.log('Success', data)
        },
        onError(error) {
            console.log('ensName', error)
        },
    })
    const toFix = 4;
    const funcName = [
        'MAX_SUPPLY',
        'FREE_MINTS_FARMLIST_THRESHOLD',
        'FREE_MINTS_PUBLIC_THRESHOLD',
        'MAX_MINT_PER_WALLET_FARMLIST',
        'MAX_MINT_PER_WALLET_PUBLIC',
        'priceFarm',
        'pricePub',
        'stateFM',
        'statePub',
        'totalSupply',
        'FREEFARM',
        'FREEPUB',
        'balanceOf'
    ];
    const CONTRACT_ADDRESS = "0x37ef980d65841c4c018e2a514a91ea07d7772ba7"
    const mintFuncName = ['mintPub', 'mintFarmlist'];
    const [isContract, setisContract] = useState({
        MAX_SUPPLY: 5555,
        FREE_MINTS_FARMLIST_THRESHOLD: 5,
        FREE_MINTS_PUBLIC_THRESHOLD: 3,
        MAX_MINT_PER_WALLET_FARMLIST: 40,
        MAX_MINT_PER_WALLET_PUBLIC: 40,
        priceFarm: "6900000000000000",
        pricePub: "6900000000000000",
        stateFM: false,
        statePub: false,
        totalSupply: 0,
        FREEFARM: 0,
        FREEPUB: 0,
        balanceOf: 0,
    });

    const [inputValue, setinputValue] = useState();
    const [debounceValue] = useDebounce(inputValue, 500);

    const [WLErrors, setWLErrors] = useState({
        INSUFFICIENT_FUNDS: false,
        UNPREDICTABLE_GAS_LIMIT: false,
    })
    const [PbErrors, setPbErrors] = useState({
        INSUFFICIENT_FUNDS: false,
        UNPREDICTABLE_GAS_LIMIT: false,
    })

    useEffect(() => {
        const handleConnectorUpdate = ({ account, chain }) => {
            if (account) {
                console.log('new account', account)
                sessionStorage.setItem('request', false);
                window.location.reload()
            } else if (chain) {
                if (chain.id !== 1) {
                    setChainError(true)
                }
                console.log('new chain', chain)
            }
        }

        if (activeConnector) {
            activeConnector.on('change', handleConnectorUpdate)
        }
    }, [activeConnector])


    useEffect(() => {
        const getData = async () => {
            if (isConnected) {
                setisLoading(true);
                if (sessionStorage.getItem('request') === 'true') {
                    sessionStorage.getItem('wl') === 'true' ? setwlWallet(true) : setwlWallet(false)
                } else {
                    try {
                        await axios.get(
                            `https://api.pointfarmors.xyz/findHexProof/${address.toLowerCase()}`
                        ).then(res => {
                            if (res.data.error) {
                                console.log('Ошибка:', res.data.error);
                                setwlWallet(false)
                                sessionStorage.setItem('wl', false)
                            } else {
                                setwlWallet(true);
                                sessionStorage.setItem('wl', true);
                                sessionStorage.setItem('proof', res.data["hexProof"]);
                                sessionStorage.setItem('request', true);
                            }
                        })
                    } catch (error) {
                        console.log('Error', error.name + " " + error.code)
                    }
                }
                setisLoading(false);
            } else {
                sessionStorage.setItem('request', false);
                console.log("Wallet not connected")
            }
        };
        getData();
    }, [isConnected])


    const { config: configpbmint, isError: pbMintError } = usePrepareContractWrite({
        address: CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: mintFuncName[0],
        args: [debounceValue],
        overrides: {
            value: (Number(isContract.pricePub) * Number(AmountForPay)).toString(),
        },
        onError(error) {
            if (error.code === 'INSUFFICIENT_FUNDS') {
                setPbErrors({ ...PbErrors, "INSUFFICIENT_FUNDS": true })
            }
            if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                setPbErrors({ ...PbErrors, "UNPREDICTABLE_GAS_LIMIT": true })
            }
            console.log('Error', error.name + " " + error.code)
        },
    })

    const { config: wlmint, isError: WlMintError } = usePrepareContractWrite({
        address: CONTRACT_ADDRESS,
        abi: contractInterface,
        functionName: mintFuncName[1],
        args: [
            debounceValue,
            sessionStorage.getItem('proof') === null ? 'proof' : sessionStorage.getItem('proof').split(','),
        ],
        overrides: {
            value: (Number(isContract.priceFarm) * Number(AmountForPay)).toString(),
        },
        onError(error) {
            if (error.code === 'INSUFFICIENT_FUNDS') {
                setWLErrors({ ...WLErrors, "INSUFFICIENT_FUNDS": true })
            }
            if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                setWLErrors({ ...WLErrors, "UNPREDICTABLE_GAS_LIMIT": true })
            }
            console.log('ProjectError', error.name + " " + error.code)
        },
    })

    const { data: mintData, writeAsync: whitelistMint } = useContractWrite({
        ...wlmint,
        onError(error) {
            if (error.name === 'UserRejectedRequestError') {
                setUserRejected(true)
            }
            console.log('ProjectError', error.name + " " + error.code)
        },
    })
    const { isLoading: ProjectLoading } = useWaitForTransaction({
        hash: mintData?.hash,
        onSuccess(data) {
            setOnSuccessWLPopup(true)
        },
        onError(error) {
            console.log('ProjectError', error.name + " " + error.code)
        },
    })

    const { data: pbdata, writeAsync: publicMint } = useContractWrite({
        ...configpbmint,
        onError(error) {
            if (error.name === 'UserRejectedRequestError') {
                setUserRejected(true)
                setNotFUNDS(false)
            }
            console.log('Error', error.name + " " + error.code)
        },
    })

    const { isLoading: pbLoading } = useWaitForTransaction({
        hash: pbdata?.hash,
        onSuccess(data) {
            setOnSuccessPopup(true)
        },
        onError(error) {
            console.log('Error', error)
        },
    })

    useContractReads({
        contracts: [
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[0],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[1],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[2],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[3],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[4],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[5],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[6],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[7],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[8],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[9],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[10],
                args: [address],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[11],
                args: [address],
            },
            {
                address: CONTRACT_ADDRESS,
                abi: contractInterface,
                functionName: funcName[12],
                args: [address],
            },
        ],
        watch: true,
        cacheTime: 10000,
        onError(error) {
            console.log('Error', error)
        },
        onSuccess(data) {
            if (isDisconnected) {
                console.log("Disconnected")
            } else {
                console.log('Success', data?.toString())
                let result = {}
                funcName.map((el, i) => {
                    if (data[i] === null) {
                        result[el] = isContract[el.toString()]
                        return true
                    } else {
                        result[el] = data[i]?.toString()
                        return true
                    }
                })
                setisContract(result);
            }
            setDataLoading(false);
        },
    })

    const clicker = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
        }
    }
    const burger = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
        } else {
            $('.header__burger').addClass('clicked');
            $('.header__burger').addClass('active');
            $('.mob-menu').addClass('active');
            $('.header-menu').addClass('active');
        }
    }

    const handleInputChange = (event) => {
        let enteredValue = event.target.value;

        enteredValue = enteredValue.slice(0, 3);
        enteredValue = enteredValue.replace('-', '');
        enteredValue = enteredValue.replace('+', '');

        if ((!isNaN(enteredValue) && Number.isInteger(parseFloat(enteredValue))) || enteredValue === '') {

            if (wlWallet) {
                if (isWl) {
                    if (enteredValue > (isContract.MAX_MINT_PER_WALLET_FARMLIST - isContract.FREEFARM)) {
                        setInputError(true)
                    } else {
                        setInputError(false)
                    }
                    AmountForPayWL(Number(enteredValue));
                    setinputValue(enteredValue);
                } else {
                    if (enteredValue > (isContract.MAX_MINT_PER_WALLET_PUBLIC - isContract.FREEPUB)) {
                        setInputError(true)
                    } else {
                        setInputError(false)
                    }
                    AmountForPayPublic(Number(enteredValue))
                    setinputValue(enteredValue);
                }
            } else {
                if (enteredValue > (isContract.MAX_MINT_PER_WALLET_PUBLIC - isContract.FREEPUB)) {
                    setInputError(true)
                } else {
                    setInputError(false)
                }
                AmountForPayPublic(Number(enteredValue))
                setinputValue(enteredValue);
            }

        }
    };
    const AmountForPayPublic = (amount) => {
        let amountForPay = amount
        if (Number(isContract.FREEPUB) < Number(isContract.FREE_MINTS_PUBLIC_THRESHOLD)) {
            if (amountForPay >= Number(isContract.FREE_MINTS_PUBLIC_THRESHOLD)) {
                amountForPay -=
                    Number(isContract.FREE_MINTS_PUBLIC_THRESHOLD) -
                    Number(isContract.FREEPUB);
            } else {
                amountForPay = 0
            }
        }
        setAmountForPay(amountForPay)
    }
    const AmountForPayWL = (amount) => {
        let amountForPay = amount
        if (Number(isContract.FREEFARM) < Number(isContract.FREE_MINTS_FARMLIST_THRESHOLD)) {
            if (Number(amountForPay) >= Number(isContract.FREE_MINTS_FARMLIST_THRESHOLD)) {
                amountForPay -=
                    Number(isContract.FREE_MINTS_FARMLIST_THRESHOLD) -
                    Number(isContract.FREEFARM);
            } else {
                amountForPay = 0
            }
        }
        setAmountForPay(amountForPay)
    }


    return (
        <>
            <div className="box">
                <div className="nav unselectable">
                    <div className="menu">
                        <div className="left-menu">
                            <div className="logo">
                                <img src="/img/logo.svg" alt="logo" />
                            </div>
                        </div>
                        <div className="right-menu">
                            <div className="soc">
                                <a href={links.OpenSea} target="_blank" rel="noreferrer" >OS</a>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">X</a>
                                <a href={links.Etherscan} target="_blank" rel="noreferrer">SCAN</a>
                            </div>
                            <div className="connect unselectable">
                                {
                                    DataLoading ? [
                                        <>
                                            <p className="unselectable">Loading...</p>
                                        </>
                                    ] : [
                                        <>
                                            {
                                                isConnected && (
                                                    <>
                                                        {ensName.data != null ? [
                                                            <>
                                                                <p onClick={openAccountModal}>{ensName.data}</p>
                                                            </>
                                                        ] : [
                                                            <>
                                                                <p onClick={openAccountModal}>{address.substr(0, 4) + '...' + address.substr(-4, 4)}</p>
                                                            </>
                                                        ]}
                                                    </>
                                                )
                                            }
                                            {
                                                isDisconnected && (
                                                    <>
                                                        <p onClick={openConnectModal}>Connect</p>
                                                    </>
                                                )
                                            }
                                            {
                                                isConnecting && (
                                                    <>
                                                        <p className="unselectable">Loading...</p>
                                                    </>
                                                )
                                            }
                                        </>
                                    ]
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mob-menu unselectable">
                        <div className="mob-menu__items" onClick={() => clicker()}>
                            <div className="connect">
                                Connect
                            </div>
                            <div className="header-soc">
                                <a href={links.OpenSea} target="_blank" rel="noreferrer" >OS</a>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">X</a>
                                <a href={links.Etherscan} target="_blank" rel="noreferrer">SCAN</a>
                            </div>
                        </div>
                    </div>
                    <div className="header__burger" id="buger" onClick={() => burger()}>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className="mint-block unselectable">
                <div className="mint-img">
                    <Carusel></Carusel>
                </div>
                <div className="mint">
                    <div className="mint-nav unselectable">

                        {
                            isConnected ? [
                                isLoading ? [
                                    <div className="nav-box wl">
                                        <p className="active">Whitelist</p>
                                        <p className="not-active">Public</p>
                                    </div>
                                ] : [
                                    wlWallet ? [
                                        <div className={isWl ? "nav-box wl" : "nav-box pub"}>
                                            <p className={isWl ? "active" : "not-active"} onClick={() => {
                                                setIsWl(true);
                                            }}>Whitelist</p>
                                            <p className={!isWl ? "active" : "not-active"} onClick={() => {
                                                setIsWl(false);
                                            }}>Public</p>
                                        </div>

                                    ] : [
                                        <div className="nav-box pub">
                                            <p className="notactive">Whitelist</p>
                                            <p className="active">Public</p>
                                        </div>
                                    ]
                                ]
                            ] : [
                                <div className="nav-box pub">
                                    <p className="not-active">Whitelist</p>
                                    <p className="active">Public</p>
                                </div>
                            ]
                        }
                    </div>
                    <div className="mint-info">

                        {
                            isConnected ? [
                                isLoading ? [
                                    <div className="info-text">
                                        <h2>Checking</h2>
                                    </div>
                                ] : [
                                    wlWallet ? [
                                        isWl ? [
                                            <div className="info-text">
                                                <h2>Whitelisted farmor!</h2>
                                                <p>Why? We just stole the entire WL from another major project because they were careless enough not to hide it properly. Claim 4 farmors for free, and purchase any extras for {Number((isContract.priceFarm / 1000000000000000000)).toFixed(toFix)} ETH each.</p>
                                            </div>
                                        ] : [
                                            <div className="info-text">
                                                <h2>...why public|?</h2>
                                                <p>You're in whitelist, but now you’re on the public mint page, and minting here is going to cost you more, so be cautious. Head back, grab your free ones, and mint extras for {Number((isContract.priceFarm / 1000000000000000000)).toFixed(toFix)} ETH instead of {Number((isContract.pricePub / 1000000000000000000)).toFixed(toFix)} ETH here.</p>
                                            </div>
                                        ]

                                    ] : [
                                        <div className="info-text white">
                                            <h2>Out of whitelist...</h2>
                                            <p>No worries, dude, you didn't lift a finger for it anyway. Just go ahead, keep farming to your $PORTAL... Or stick around and claim your {isContract.FREEPUB} free farmors and purchase any extras for {Number((isContract.pricePub / 1000000000000000000)).toFixed(toFix)} ETH each.</p>
                                        </div>
                                    ]
                                ]
                            ] : [
                                <div className="info-text white">
                                    <h2>You in WL or not?</h2>
                                    <p>We snagged whole WL from another project lol, and those guys didn't do a great job hiding it. Pretty funny, huh? Your wallet might just be part of that haul, check it below.</p>
                                </div>
                            ]
                        }
                        <div className="counter">
                            <p>{isContract.totalSupply} / {isContract.MAX_SUPPLY}</p>
                            {
                                wlWallet ? [
                                    isWl ? [
                                        <p>Your progress: {isContract.FREEFARM} / {isContract.MAX_MINT_PER_WALLET_FARMLIST}</p>
                                    ] : [
                                        <p>Your progress: {isContract.FREEPUB} / {isContract.MAX_MINT_PER_WALLET_PUBLIC}</p>
                                    ]
                                ] : [
                                    <p>Your progress: {isContract.FREEPUB} / {isContract.MAX_MINT_PER_WALLET_PUBLIC}</p>
                                ]
                            }
                        </div>
                        <div className="input-count">
                            {
                                isConnected ?
                                    [
                                        wlWallet ? [
                                            isWl ? [
                                                <>

                                                    {
                                                        InputError ? [
                                                            <>
                                                                <input className="error" type="number" min="0" max={isContract.MAX_MINT_PER_WALLET_FARMLIST} placeholder={"Enter mint amount (max." + isContract.MAX_MINT_PER_WALLET_FARMLIST + ")"} id="count" value={inputValue} onChange={handleInputChange} />
                                                                <p>
                                                                    {Number(isContract.MAX_MINT_PER_WALLET_FARMLIST) - Number(isContract.FREEFARM) < 0 ? [
                                                                        "You've reached the limit"
                                                                    ] : [
                                                                        "Max " + (Number(isContract.MAX_MINT_PER_WALLET_FARMLIST) - Number(isContract.FREEFARM)) + " farmors"
                                                                    ]}
                                                                </p>
                                                            </>

                                                        ] : [
                                                            <>
                                                                <input type="number" min="0" max={isContract.MAX_MINT_PER_WALLET_FARMLIST} placeholder={"Enter mint amount (max." + isContract.MAX_MINT_PER_WALLET_FARMLIST + ")"} id="count" value={inputValue} onChange={handleInputChange} />
                                                                <p style={{ visibility: "hidden" }}>It's all right.</p>
                                                            </>
                                                        ]
                                                    }
                                                </>
                                            ] : [
                                                <>
                                                    {
                                                        InputError ? [
                                                            <>
                                                                <input className="error" type="number" min="0" max={isContract.MAX_MINT_PER_WALLET_PUBLIC} placeholder={"Enter mint amount (max." + isContract.MAX_MINT_PER_WALLET_PUBLIC + ")"} id="count1" value={inputValue} onChange={handleInputChange} />
                                                                <p>
                                                                    {Number(isContract.MAX_MINT_PER_WALLET_PUBLIC) - Number(isContract.FREEPUB) < 0 ? [
                                                                        "You've reached the limit"
                                                                    ] : [
                                                                        "Max " + (Number(isContract.MAX_MINT_PER_WALLET_PUBLIC) - Number(isContract.FREEPUB)) + " farmors"
                                                                    ]}
                                                                </p>
                                                            </>
                                                        ] : [
                                                            <>
                                                                <input type="number" min="0" max={isContract.MAX_MINT_PER_WALLET_PUBLIC} placeholder={"Enter mint amount (max." + isContract.MAX_MINT_PER_WALLET_PUBLIC + ")"} id="count1" value={inputValue} onChange={handleInputChange} />
                                                                <p style={{ visibility: "hidden" }}>It's all right.</p>
                                                            </>
                                                        ]
                                                    }
                                                </>

                                            ]
                                        ] : [
                                            <>
                                                {
                                                    InputError ? [
                                                        <>
                                                            <input className="error" type="number" min="0" max={isContract.MAX_MINT_PER_WALLET_PUBLIC} placeholder={"Enter mint amount (max." + isContract.MAX_MINT_PER_WALLET_PUBLIC + ")"} id="count1" value={inputValue} onChange={handleInputChange} />
                                                            <p>
                                                                {Number(isContract.MAX_MINT_PER_WALLET_PUBLIC) - Number(isContract.FREEPUB) < 0 ? [
                                                                    "You've reached the limit"
                                                                ] : [
                                                                    "Max " + (Number(isContract.MAX_MINT_PER_WALLET_PUBLIC) - Number(isContract.FREEPUB)) + " farmors"
                                                                ]}
                                                            </p>
                                                        </>
                                                    ] : [
                                                        <>
                                                            <input type="number" min="0" max={isContract.MAX_MINT_PER_WALLET_PUBLIC} placeholder={"Enter mint amount (max." + isContract.MAX_MINT_PER_WALLET_PUBLIC + ")"} id="count1" value={inputValue} onChange={handleInputChange} />
                                                            <p style={{ visibility: "hidden" }}>It's all right.</p>
                                                        </>
                                                    ]
                                                }
                                            </>
                                        ]
                                    ] : [
                                        <>
                                            <input type="number" min="0" max={isContract.MAX_MINT_PER_WALLET_PUBLIC} placeholder={"Enter mint amount (max." + isContract.MAX_MINT_PER_WALLET_PUBLIC + ")"} id="count1" value={inputValue} onChange={handleInputChange} />
                                            <p style={{ visibility: "hidden" }}>It's all right.</p>
                                        </>
                                    ]


                            }
                        </div>
                        {
                            isConnecting ? [
                                <div className="mint-btn unselectable">
                                    Connecting...
                                </div>
                            ] : [
                                isDisconnected ? [
                                    <div className="mint-btn unselectable" onClick={openConnectModal}>
                                        Connect wallet
                                    </div>
                                ] : [
                                    wlWallet ? [
                                        isWl ? [
                                            Number(isContract.FREEFARM) === Number(isContract.FREE_MINTS_FARMLIST_THRESHOLD) ? [
                                                <div className="mint-btn not-active unselectable">
                                                    <p>Whitelist mint for {Number(AmountForPay) !== 0 ? (Number((isContract.priceFarm / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                                </div>
                                            ] : [
                                                InputError ? [
                                                    <div className="mint-btn not-active unselectable">
                                                        <p>Whitelist mint for {Number(AmountForPay) !== 0 ? (Number((isContract.priceFarm / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                                    </div>
                                                ] : [
                                                    <div className="mint-btn unselectable" onClick={() => {
                                                        if (WlMintError) {
                                                            setNotFUNDS(WLErrors.INSUFFICIENT_FUNDS)
                                                            setMaxMintWL(WLErrors.UNPREDICTABLE_GAS_LIMIT)
                                                        } else {
                                                            !ProjectLoading ? whitelistMint() : console.log("Идет транзакция")
                                                        }
                                                    }}>
                                                        {
                                                            ProjectLoading ? [
                                                                <>
                                                                    <p>Minting...</p>
                                                                </>
                                                            ] : [
                                                                <>
                                                                    <p>Whitelist mint for {Number(AmountForPay) !== 0 ? (Number((isContract.priceFarm / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                                                </>
                                                            ]
                                                        }

                                                    </div>
                                                ]
                                            ]
                                        ] : [
                                            Number(isContract.FREEPUB) === Number(isContract.FREE_MINTS_PUBLIC_THRESHOLD) ? [
                                                <div className="mint-btn not-active unselectable">
                                                    <p>Public mint for {Number(AmountForPay) !== 0 ? (Number((isContract.pricePub / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                                </div>
                                            ] : [
                                                InputError ? [
                                                    <div className="mint-btn not-active unselectable">
                                                        <p>Public mint for {Number(AmountForPay) !== 0 ? (Number((isContract.pricePub / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                                    </div>
                                                ] : [
                                                    <div className="mint-btn unselectable" onClick={() => {
                                                        if (pbMintError) {
                                                            setNotFUNDS(PbErrors.INSUFFICIENT_FUNDS)
                                                            setMaxMintPb(PbErrors.UNPREDICTABLE_GAS_LIMIT)
                                                        } else {
                                                            !pbLoading ? publicMint() : console.log("Transaction in progress")
                                                        }
                                                    }}>
                                                        {
                                                            pbLoading ? [
                                                                <>
                                                                    <p>Minting...</p>
                                                                </>
                                                            ] : [
                                                                <>
                                                                    <p>Public mint for {Number(AmountForPay) !== 0 ? (Number((isContract.pricePub / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                                                </>
                                                            ]
                                                        }

                                                    </div>
                                                ]
                                            ]
                                        ]
                                    ] : [
                                        InputError ? [
                                            <div className="mint-btn not-active unselectable">
                                                <p>Public mint for {Number(AmountForPay) !== 0 ? (Number((isContract.pricePub / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                            </div>
                                        ] : [
                                            <div className="mint-btn unselectable" onClick={() => {
                                                if (pbMintError) {
                                                    setNotFUNDS(PbErrors.INSUFFICIENT_FUNDS)
                                                    setMaxMintPb(PbErrors.UNPREDICTABLE_GAS_LIMIT)
                                                } else {
                                                    !pbLoading ? publicMint() : console.log("Transaction in progress")
                                                }
                                            }}>
                                                {
                                                    pbLoading ? [
                                                        <>
                                                            <p>Minting...</p>
                                                        </>
                                                    ] : [
                                                        <>
                                                            <p>Public mint for {Number(AmountForPay) !== 0 ? (Number((isContract.pricePub / 1000000000000000000) * AmountForPay).toFixed(toFix)) + " ETH" : "free"}</p>
                                                        </>
                                                    ]
                                                }

                                            </div>
                                        ]
                                    ]
                                ]
                            ]
                        }
                    </div>
                </div>
            </div>
            <Popup active={NotFUNDS} setActive={setNotFUNDS}>
                <div className="popup-text">
                    <div className="popup-text__text unselectable">
                        <p>Get back to your minting wallet or make sure you've funded this one.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={UserRejected} setActive={setUserRejected} >
                <div className="popup-text">
                    <div className="popup-text__text unselectable">
                        <p>Not sure if you wanna mint? Give it a try with one, you might thank yourself later.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={MaxMintWL} setActive={setMaxMintWL} >
                <div className="popup-text">
                    <div className="popup-text__text unselectable">
                        <p>It seems like you're attempting to go beyond this limit.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={MaxMintPb} setActive={setMaxMintPb} >
                <div className="popup-text">
                    <div className="popup-text__text unselectable">
                        <p>It seems like you're attempting to go beyond this limit.</p>
                    </div>
                </div>
            </Popup>
            <Popup active={OnSuccessPopup} setActive={setOnSuccessPopup} >
                <div className="popup-text">
                    <div className="popup-text__text unselectable">
                        <p>Go to OS and check your farmors now</p>
                    </div>
                    <div className="popup-btn-box unselectable">
                        <a href="https://twitter.com/intent/tweet?text=⚡️ POWER POST ⚡️%0d%0dAND VERY INTERACTION WITH THIS TWEET WILL GET 2,000 $PORTAL%0d%0dDon't believe me? try it for yourself 🫵🏻%0d%0dP.S. JUST MINTED MY FARMOOOR, JOIN THE GANG (@PointFarmors)&related=PointFarmors" className='popup-btn btn-yellow' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">Tweet it</p>
                        </a>
                        <a href={`https://etherscan.io/tx/${mintData?.hash}`} className='popup-btn btn-white' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">Etherscan</p>
                        </a>
                    </div>
                </div>
            </Popup>
            <Popup active={OnSuccessWLPopup} setActive={setOnSuccessWLPopup} >
                <div className="popup-text">
                    <div className="popup-text__text unselectable">
                        <p>Go to OS and check your farmors now</p>
                    </div>
                    <div className="popup-btn-box unselectable">
                        <a href="https://twitter.com/intent/tweet?text=⚡️ POWER POST ⚡️%0d%0dAND VERY INTERACTION WITH THIS TWEET WILL GET 2,000 $PORTAL%0d%0dDon't believe me? try it for yourself 🫵🏻%0d%0dP.S. JUST MINTED MY FARMOOOR, JOIN THE GANG (@PointFarmors)&related=PointFarmors" className='popup-btn btn-yellow' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">Tweet it</p>
                        </a>
                        <a href={`https://etherscan.io/tx/${mintData?.hash}`} className='popup-btn btn-white' target="_blank" rel="noreferrer">
                            <p style={{ color: "#000" }} className="unselectable">Etherscan</p>
                        </a>
                    </div>
                </div>
            </Popup>
            <Popup active={ChainError} setActive={setChainError} >
                <div className="popup-text">
                    <div className="popup-text__text unselectable">
                        <p>switch to the ethereum mainnet, please.</p>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default App;
